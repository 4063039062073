$(function ($) {
    'use strict';
    var win = window;


    window['$win'] = $(window);
    
    win['origins'] = {
        api: 'https://auth.gybol.com/api/account'
        //api: 'https://localhost:44396/api/account'
    };


    win['preventDefault'] = function (e) {
        if (typeof e !== 'object') {
            return false;
        }
        if (e.stopPropagation) {
            e.stopPropagation();
        }
        else {
            e.cancelBubble = true;
        }
        if (e.preventDefault) {
            e.preventDefault();
        }
        else {
            e.returnValue = false;
        }
    };


    //--------------------------------------------------------
    //	Google reCaptcha (invisible)
    //--------------------------------------------------------
    win.reCaptchaToken = false;
    win.recaptchaDone = (e) => {
        $.event.trigger('reCaptcha', [reCaptchaToken = e] );
    };
    win.captchaReady = _ => {
        $.event.trigger('captchaReady');
    };



    //--------------------------------------------------------
    //	Scroll To Top 
    //--------------------------------------------------------
    var SCROLLUP = '.scrollup';

    $win.scroll(function () {
        if ($(this).scrollTop() > 100) {
            $(SCROLLUP).fadeIn();
        } else {
            $(SCROLLUP).fadeOut();
        }
    });

    $(SCROLLUP).click(function () {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });



    var devHelper = document.getElementById('res');
    if (devHelper !== "undefined")
    {
        var str = 'h: ' + $win.outerHeight() + '&nbsp;&nbsp;&nbsp;w: ' + $win.outerWidth() + '&nbsp;&nbsp;&nbsp;r: ' + win.devicePixelRatio;
        $('#res').html(str);

        $win.on('resize', function () {
            var str = 'h: ' + $win.outerHeight() + '&nbsp;&nbsp;&nbsp;w: ' + $win.outerWidth() + '&nbsp;&nbsp;&nbsp;r: ' + win.devicePixelRatio;
            $('#res').html(str);
        });
    }



    //--------------------------------------------------------
    //	Linksbar Enhancements
    //--------------------------------------------------------
    //
    var linksDropdowns = $('#linksbar .dropdown'),
        linksCollapse = $('#linksbar .collapse');


    linksDropdowns
        .on('show.bs.dropdown', function (e) {
            // add a slide down animation to the menu
            $(this).find('.dropdown-menu').first().stop(true, true).slideDown();

            // if the collapse is open, then close it
            var collapse = linksCollapse.data("bs.collapse");
            if (collapse) {
                if (collapse.transitioning)
                    return;

                collapse.hide();
            }
            
        })

    
        .on('hide.bs.dropdown', function (e) {
            // add a slideup animation to the menu
            $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
        });



    linksCollapse
        .on('show.bs.collapse', function (evt) {

            // find the dropdown menus and make sure they are closed
            var dropdowns = linksDropdowns.data("bd.dropdown");
            if (dropdowns) {
                dropdowns.hide();
            }
        });





});
