CookieConsent = (function () {

    const COOKIE_YEARS = 5,
        COOKIE_DOMAIN = 'gybol.com',
        COOKIE_NAME = 'gyConsent',
        DIALOG_CONTAINER = '#consent';


    var haveConsent = null,
        eventListeners = {
            "BeforeDialogClosed": [],
            "ConsentChanged": []
        };

    function _createCookie(value) {
        var millisecondsPerYear = 365 * 24 * 60 * 60 * 1000,
            date = new Date();

        date.setTime(date.getTime() + COOKIE_YEARS * millisecondsPerYear);

        var expires = "; expires=" + date.toGMTString();
        document.cookie = COOKIE_NAME + "=" + value + expires + "; domain=" + COOKIE_DOMAIN + "; path=/; samesite=lax";
    }

    function _readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    function _fireEvent(eventName, args) {
        var collection = eventListeners[eventName];
        if (!collection) return;

        for (var index = 0; index < collection.length; index++) {
            try {
                collection[index].apply(this, args);
            } catch (err) {
                console.error('CookieConsent: Event listener of ' + eventName + ' event thew error: ' + err);
            }
        }
    }
    
    function _closeConsentDialog() {
        var dialog = $(DIALOG_CONTAINER)[0];

        _fireEvent("BeforeDialogClosed", [dialog]);
        document.body.removeChild(dialog);
    }



    haveConsent = _readCookie(COOKIE_NAME);

    return {
        Update: function (consent) {
            var oldState = haveConsent;

            haveConsent = consent ? "yes" : "no";

            _createCookie(haveConsent);

            if (haveConsent !== oldState) {
                _fireEvent('ConsentChanged', [consent]);
            }

            if (consent) {
                _closeConsentDialog();
            }
        },

        canTrack: function () {
            return haveConsent === "yes";
        },

        addEvent: function (eventName, listener) {
            if (typeof listener !== "function") return;

            var collection = eventListeners[eventName];
            if (!collection) return;

            collection.push(listener);
        },

        removeEvent: function (eventName, listener) {
            if (typeof listener !== "function") return;

            var collection = eventListeners[eventName];
            if (!collection) return;

            var index = collection.indexOf(listener);
            if (index < 0) return;

            collection.splice(index, 1);
        }
    };


})();
