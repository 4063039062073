

$.fn.disabled = function (isDisabled) {
    for (element of this) {
        element = element.nodeName == 'FORM' ? $(":submit, .next") : $(element);
        element.disableBtn(isDisabled);
    }
};

$.fn.disableBtn = function (isDisabled)
{
    this.toggleClass('disabled working', isDisabled)
        .attr('disabled', isDisabled ? true : null);
};

$.extend($.validator.defaults, {
    //debug: true,

    // Disable submit buttons to avoid any double presses and provide the 
    // user with affirmative feedback then trigger the form to actually submit
    submitHandler: function (form, e) {
        if (!this.settings.debug) {

            var $form = $(form),
                handler = $form.data('submitHandler');

            $form.disabled(true);
            
            if (handler) {
                form.classList.add('was-validated');
                handler(form, e);
            }
            else {
                form.submit();
            }
        }
    },

    // Called on submit when the form in invalid.  If the Constraint API is in
    // use, then force it to report the errors
    invalidHandler: function (event, validator) {
        var form = validator.currentForm;

        $(form).addClass('was-validated');

        if (form.reportValidity !== undefined) {
            form.reportValidity();
        }
    },
    
    // this is basically the same as the default handler used by jQuery.Validate
    // but instead of creating label elements it uses the HTML5 Constraint API (if
    // it's available).
    // If the Constraint API isn't available (or only partially available [Edge 16.x]) 
    // then we just go back to the default 
    showErrors: function (errorMap, errorList) {

        var validator = this,
            settings = validator.settings;

        if (validator.currentForm.reportValidity !== undefined) {
            // For any valid elements, make sure there is no customError message attached
            for (i = 0, elements = validator.validElements(); elements[i]; i++) {
                elements[i].setCustomValidity('');

                if (settings.unhighlight) {
                    settings.unhighlight.call(validator, elements[i], settings.errorClass, settings.validClass);
                }
            }

            if (errorList.length) {
                validator.toShow = validator.toShow.add(validator.containers);
            }


            var i, elements, error, element;

            // Next loop through the list of errors and attach a customError 
            for (i = 0; errorList[i]; i++) {
                error = errorList[i];
                element = error.element;

                element.setCustomValidity(error.message);

                if (settings.highlight) {
                    settings.highlight.call(validator, element, settings.errorClass, settings.validClass);
                }
            }

            validator.toHide = validator.toHide.not(validator.toShow);
            validator.hideErrors();
            validator.addWrapper(validator.toShow).show();
        }
        else {
            // The Constraint API isn't available (or is only partially available) so
            // go back to the default
            validator.defaultShowErrors();
        }
    }


});
