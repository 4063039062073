(function ($) {
    'use strict';

    var EVENT_NAMESPACE = '.password',
        KEYUP_EVENT = 'keyup' + EVENT_NAMESPACE,
        CHANGE_EVENT = 'change' + EVENT_NAMESPACE,
        CLICK_EVENT = 'click' + EVENT_NAMESPACE,

        $doc = $(document),

        // taken from the Yahoo! hack list of popular passwords, and other places
        badwords = /^(?:pa*s*w*o*r*d*|we*lco*me|let*me*in*|ninja|ninjacat|bonjour|sunshine|princess|qwe[rtyuiop]*|asd[fghjkl]*|zxc[vbnm]*|freedom|writer|iloveyou*|rockyou|football|baseball)\d*$/i
        ;


    $.fn.validatePassword = function (options) {
        // if nothing is selected, return nothing.
        if (!this.length) return;

        // check if a password form has already been created
        var widget = $.data($doc, 'password');
        if (widget) {
            if (widget._container != this) {
                console.log('passwordWidget: only 1 instance may be used per page');
                return;
            }
        }
        else {
            widget = new $.passwordWidget(options, this);
            $.data($doc, 'password', widget);
        }

        return widget;
    };

    $.passwordWidget = function (options, container) {
        this.options = $.extend(true, {}, $.passwordWidget.defaults, options);
        this._container = container;
        this.init(container);
    };

    $.extend($.passwordWidget, {

        defaults: {
            threshold: {
                error: 50,
                warning: 75
            },
            minLength: 8,
            progress: '#pwbar',
            submitbtn: false,
            labels: {
                bad: 'No good',
                warning: 'Nearly',
                good: 'That\'s Good',
                perfect: 'Superstar!'

            },
            msgs: {
                tooshort: 'Password should be at least 8 characters',
                nomatch: 'The passwords don\'t match',
                notstrong: 'The password is not strong enough, try adding numbers uppercase letters or symbols',
            }
        },

        prototype: {
            init: function ($container) {
                var widget = this,
                    options = widget.options,

                    $pw1 = $container.find('#password1'),
                    $input1 = $pw1.find('input'),
                    $pw2 = $container.find('#password2'),
                    $input2 = $pw2.find('input'),

                    $showToggle = $container.find('#pwvisible');

 
                $.extend(true, widget, {
                    pw1: {
                        $block: $pw1,
                        $input: $input1,
                        $feedback: $pw1.find('.feedback')
                    },
                    pw2: {
                        $block: $pw2,
                        $input: $input2,
                        $feedback: $pw2.find('.feedback')
                    },
                    $progress: $(options.progress).find('.progress-bar')
                });


                if (options.submitbtn) {
                    options.submitbtn = $(options.submitbtn)
                        .attr('disabled', 'disabled')
                        .addClass('disabled');
                }

                $showToggle.on(CLICK_EVENT, toggleShowPassword.bind(this));

                $input1
                    .on(KEYUP_EVENT, validateStrength.bind(this))           // $.proxy(validateStrength, widget.options))
                    .on(CHANGE_EVENT, validatePassword1.bind(this));        // $.proxy(validatePassword1, widget.options));

                $input2.on(KEYUP_EVENT, validatePassword2.bind(this));      //$.proxy(validatePassword2, options));
            }
        }

    });

    function getSpecialCharacterCount(password, pattern, limit) {
        var match = password.match(pattern),
            count = 0;

        match && (count = match.length);
        return count > limit ? limit : count;
    }


    function getStrength(widget) {
        var options = widget.options,
            thresholds = options.threshold,

            pwVal = widget.pw1.$input.val(),
            pwLength = pwVal.length,

            maxCharacters = Math.ceil(((100 - thresholds.error) / 3) / 10),

            totalNumerics = getSpecialCharacterCount(pwVal, /[0-9]/g, pwLength),

            numericCount = getSpecialCharacterCount(pwVal, /[0-9]/g, maxCharacters),
            symbolsCount = getSpecialCharacterCount(pwVal, /\W/g, maxCharacters),
            upperCount = getSpecialCharacterCount(pwVal, /[A-Z]/g, maxCharacters),
            lowerCount = getSpecialCharacterCount(pwVal, /[a-z]/g, 1),

            letters = new Object(),
            justLetters = pwVal,
            lettersScore = thresholds.warning / options.minLength,
            lettersCount = 0;


        for (var i = 0; i < pwLength; i++) {
            letters[pwVal[i]] = (letters[pwVal[i]] || 0) + 1;
        }
        // turn the letters into a score, the more a letter appears, the less it scores
        for (var letter in letters) {
            lettersCount += Math.ceil((lettersScore / letters[letter]));
        }

        // the letters can never exceed the error threshold, we need
        // some special characters to make up the rest of the  score
        if (lettersCount > thresholds.error) {
            lettersCount = thresholds.error;
        }

        // if the password entered is longer than the minimum requirement
        // then give some points for the extra characters
        var excessLength = (pwLength - options.minLength) * 1.5;
        if (excessLength > 0) {
            if (excessLength > 10)
                excessLength = 10;
        } else 
            excessLength = 0;


        var strength = lettersCount + excessLength + (numericCount * 5) + (symbolsCount * 10) + (upperCount * 5) + (lowerCount * 5);
        if (strength < 0)
            strength = 0;

        else if (strength > 100)
            strength = 100;


        // some more checks, not needed is we're already failing
        if (strength > thresholds.error) {

            if (totalNumerics == pwLength) {
                // invalidate all numeric passwords
                strength = thresholds.error;
            }
            else if (badwords.test(pwVal)) {
                // invalidate passwords using bad/common words
                strength = thresholds.error;
            }
            else if (pwLength < options.minLength) {
                // make sure the password meets minimum length requirements
                strength = thresholds.error;
            }
        }

        //console.log(
        //    'Len: ' + pwLength
        //    + ' - Numeric: ' + numericCount
        //    + ' - Letters:' + lettersCount
        //    + ' - Symbols:' + symbolsCount
        //    + ' - Upper:' + upperCount
        //    + ' - Strength:' + strength
        //    + ' - Password:' + pwVal
        //);

        return strength;
    }


    function validateStrength() {

        var widget = this,
            options = widget.options,
            thresholds = options.threshold,
            strength = getStrength(widget),
            asPercent = strength + '%',
            newClass = '',
            successLabel = '';

        if (strength <= thresholds.error) {
            newClass = "danger";
            successLabel = (strength < 20) ? '' : options.labels.bad;
        }
        else if (strength < thresholds.warning) {
            newClass = "warning";
            successLabel = options.labels.warning;
        }
        else {
            newClass = "success";
            successLabel = options.labels.good;
            if (strength == 100) 
                successLabel = options.labels.perfect;
        }

        widget.$progress
            .attr('class', 'progress-bar bg-' + newClass)
            .css('width', asPercent)
            .attr('aria-valuenow', strength)
            .attr('title', asPercent)
            .find('span').html(successLabel)
            .next('.sr-only').html('New Password Rating: ' + asPercent);

        this.strength = strength;

        if (widget.pw1.feedbackOn) {
            validatePassword1.call(widget);
        }
    }

    function giveFeedback(ui, result, error) {
        ui.$input.removeClass('border-success border-warning border-danger');
        ui.$block.removeClass('success warning danger');
        ui.$feedback.html('');

        if (error) {
            ui.$feedback.html(error);
            ui.feedbackOn = true;
        } 
        if (result) {
            ui.$input.addClass('border-' + result);
            ui.$block.addClass(result);
        }

        //// Save these so they can be accessed by jQueryValidation handlers
        //$.extend(ui, {
        //    result: result,
        //    msg: error
        //});
    }

    function validatePassword1() {
        var widget = this,
            options = widget.options,
            thresholds = options.threshold,

            ui = widget.pw1,
            strength = widget.strength,
            status = 'danger',
            pwVal = ui.$input.val(),
            pwLength = pwVal.length,
            error = false;

        if (pwLength == 0) {
            error = false;
            status = false;
            ui.feedbackOn = false;
        }
        else if (pwLength < options.minLength) {
            error = options.msgs.tooshort;

        } else if (strength <= thresholds.error) {
            error = options.msgs.notstrong;

        } else if (strength < thresholds.warning) {
            status = 'warning';

        } else {
            status = 'success';
        }

        giveFeedback(ui, status, error);
        widget.p1status = status;

        validatePassword2.call(this);

    }

    function validatePassword2() {

        var widget = this,
            options = widget.options,

            ui = widget.pw2,
            strength = widget.strength,
            status = widget.status,
            password1Text = widget.pw1.$input.val(),
            password2Text = ui.$input.val(),
            error = false;

        if (password2Text.length > 0) {
            if (password1Text !== password2Text) {
                status = 'danger';
                error = options.msgs.nomatch
            } else {
                status = 'success';
            }

            giveFeedback(ui, status, error);
        }
        else 
            giveFeedback(ui, false, false);


        if (options.submitbtn) {
            var canContinue = !(status == 'success' && (widget.p1status == 'success' || widget.p1status == 'warning') );
            options.submitbtn.attr('disabled', canContinue).toggleClass('disabled', canContinue);
        }
    }

    function toggleShowPassword() {
        var me = this,
            $inputs = $(me.pw1.$input).add(me.pw2.$input);

        me._container.toggleClass('showpw');
        $inputs.attr("type", me._container.hasClass('showpw') ? "text" : "password");
    }

    /// The form is disabled until the widget is happy with the password
    /// which means validation can't happen until the password meets the requirements

    //if (typeof $.validator == 'function') {
    //    // Validates the password has been entered and meets acceptable standards
    //    $.validator.addMethod("password",
    //        function (value, element, param) {
    //            var passwordWidget = $.data($doc, 'password'),
    //                formatter = $.validator.format;
    //
    //            $.validator.messages.password = formatter(passwordWidget.options.msgs.notstrong);
    //            return passwordWidget.isValid;
    //        },
    //        $.passwordWidget.defaults.msgs.notstrong   // default message
    //    );


    //    // Validates the confirmation password has been entered and that the two match up
    //    $.validator.addMethod("password2",
    //        function (value, element, param) {
    //            var passwordWidget = $.data($doc, 'password');
    //            return passwordWidget.isValid;
    //        },
    //        $.passwordWidget.defaults.msgs.nomatch        // default message
    //    );

    //}


})($);