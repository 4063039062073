$(function ($) {
    'use strict';

    // Email verification

    var button = document.getElementById('verifyEmail'),
        STATE = "state-",
        WORKING = STATE + "working";

    if (button) {
        var $button = $(button);
        $button.on('click', function () {
            var $alert = $button.closest('.alert-worker');

            $alert.addClass(WORKING);

            $.ajax({
                url: origins.api + '/sendVerificationEmail',
                type: 'get',
                data: {},
                headers: {
                    'gy-xcsrf': xCSRF ,
                    'gy-sub' : xSub
                },
                dataType: "json"
            })

            .done(function (data) {
                $alert.removeClass(WORKING).addClass(STATE + "done");
            })

            .fail(function () {
                $alert.removeClass(WORKING).addClass(STATE + "fail");

            });
        });
    }



});
